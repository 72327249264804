<template>
  <div :class="['teacher-item']">
    <div class="img">
      <img :src="data.avatar" />
    </div>
    <p class="title">{{data.name}}</p>
    <p class="desc" v-for="(title,i) in data.titles" :key="i">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: 'Portal-title',
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.teacher-item {
  text-align: center;
    width: 214px;
    height: 330px;
    background-color: #F4F4F4;
    border-radius: 3px;
    display: inline-block;
    padding: 10px;
    .img {
      width: 194px;
      overflow: hidden;
      height: 120px;
      // background-color: #333;
      margin: auto;
      img {
        width: 100%;
      }
    }
    p {
      line-height: 1;
      &.title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      &.desc {
        font-size: 13px;
        color: #333;
        width: 150px;
        height: 28px;
        line-height: 14px;
        overflow: hidden;
        margin: 0 auto 10px;
        // margin-bottom: 10px;
      }
    }
}
</style>
