<template>
  <div class="teachers-wrapper">
    <template v-if="titleConfig.isInit && titleConfig.serviceLevel==1">
      <el-empty
        description="暂未开通此功能"
        style="padding-top: 10%;"
      ></el-empty>
    </template>
    <template v-else>
      <banner-bg :type="3">
        <div class="teachers-base">
          <new-banner
            :bannerList="bannerList"
            :height="600"
          ></new-banner>
        </div>
      </banner-bg>
      <div class="teachers-body">
        <Title
          :title="config.title1"
          :desc="config.title2"
        ></Title>
        <div class="body-1">
          <ul>
            <li>
              <div class="img">
                <img
                  src="@/assets/image/Teachgroup/College.png"
                  alt=""
                >
              </div>
              <p class="title">{{config.feature1}}</p>
              <p class="desc">{{config.featureSub1}}</p>
            </li>
            <li>
              <div class="img">
                <img
                  src="@/assets/image/Teachgroup/ITLogo.png"
                  alt=""
                >
              </div>
              <p class="title">{{config.feature2}}</p>
              <p class="desc">{{config.featureSub2}}</p>
            </li>
            <li>
              <div class="img">
                <img
                  src="@/assets/image/Teachgroup/ComputerLogo.png"
                  alt=""
                >
              </div>
              <p class="title">{{config.feature3}}</p>
              <p class="desc">{{config.featureSub3}}</p>
            </li>
            <li>
              <div class="img">
                <img
                  src="@/assets/image/Teachgroup/CSTA.png"
                  alt=""
                >
              </div>
              <p class="title">{{config.feature4}}</p>
              <p class="desc">{{config.featureSub4}}</p>
            </li>
          </ul>
        </div>
        <div class="teacher-list">
          <el-card :body-style="{ padding: '15px 40px 0 40px' }">
            <el-button
              @click="handleCurrentChange(1,'pre')"
              v-if="dataTotal1 > 4 && pageNo1 > 1"
              class="teacher-btn-left"
              icon="el-icon-arrow-left"
              circle
              size="mini"
            ></el-button>
            <ul>
              <li
                v-for="(item,i) in teacherList1"
                :key="i"
              >
                <Teacher :data="item"></Teacher>
              </li>
            </ul>
            <el-button
              @click="handleCurrentChange(1,'next')"
              v-if="dataTotal1 > 4 && pageNo1 < (dataTotal1/pageNo1)"
              class="teacher-btn-right"
              icon="el-icon-arrow-right"
              circle
              size="mini"
            ></el-button>
          </el-card>
        </div>
        <Title
          :title="config.teacherTitle1"
          :desc="config.teacherTitle2"
        ></Title>
        <div class="teacher-list">
          <el-card :body-style="{ padding: '15px 40px 0 40px' }">
            <ul>
              <li
                v-for="(item,i) in teacherList2"
                :key="i"
              >
                <Teacher :data="item"></Teacher>
              </li>
            </ul>
            <div class="table-pagination">
              <el-pagination
                background
                @current-change="val => handleCurrentChange(2,val)"
                :current-page="pageNo2"
                :page-size="8"
                layout="total, prev, pager, next,jumper"
                :total="dataTotal2"
              >
              </el-pagination>
            </div>
          </el-card>
        </div>
        <!-- <div class="info-block" :style="{ 'backgroundImage':'url('+ bottomBackImg +')' }">
        <div class="info-title1">我要开课</div>
        <div class="info-title2">入驻亿启编投稿课程，坐享高效工具和平台能力</div>
        <div class="info-btn">入驻申请联系：team@eqibian.com</div>
      </div> -->
      </div>
    </template>
  </div>
</template>

<script>
const bottomBackImg = require('@/assets/image/teaching/bottom.png')
import { getBanners, getTeachers, getTeacherConfig } from '@/api/common';

import Title from '@/components/Title';
import Teacher from '@/components/Teacher';
import BannerBg from '@/components/BannerBg.vue';
import NewBanner from '@/components/NewBanner.vue';

import { mapState } from 'vuex'

export default {
  components: {
    Title,
    Teacher,
    BannerBg,
    NewBanner
  },
  data() {
    return {
      bannerList: [],
      teacherList1: [],
      teacherList2: [],
      page: 0,
      dataTotal1: 0,
      dataTotal2: 0,
      pageNo1: 1,
      pageNo2: 1,
      bottomBackImg,
      config: {
        feature1: null,
        feature2: null,
        feature3: null,
        feature4: null,
        featureSub1: null,
        featureSub2: null,
        featureSub3: null,
        featureSub4: null,
        teacherTitle1: null,
        teacherTitle2: null,
        title1: null,
        title2: null,
      }
    }
  },
  computed: {
    ...mapState({
      titleConfig: (state) => state.siteConfigs,
    })
  },
  created() {
    this.getBannerList();
    this.getTeacherData(1);
    this.getTeacherData(2);
    this.getTeachersConfig();
  },
  methods: {
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'mst',
        platform: 'yqb',
        isPortal: '1'
      }
      getBanners(param).then(res => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      })
    },
    getTeacherData(type) {
      let param = {
        pageSize: type == 1 ? 4 : 8,
        teamId: type,
        pageNo: type == 1 ? this.pageNo1 : this.pageNo2,
      }
      getTeachers(param).then(res => {
        if (res.success) {
          if (res.result.records && res.result.records != '') {
            for (let i in res.result.records) {
              let titles = res.result.records[i].titles.split(',');
              res.result.records[i].titles = titles;
            }
          }
          if (type == 1) {
            this.teacherList1 = res.result.records;
            this.dataTotal1 = res.result.total;
          } else {
            this.teacherList2 = res.result.records;
            this.dataTotal2 = res.result.total;
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
    handleCurrentChange(type, val) {
      if (type == 1) {
        this.pageNo1 = val == 'pre' ? this.pageNo1 - 1 : this.pageNo1 + 1;
      } else {
        this.pageNo2 = val;
      }
      this.getTeacherData(type);
    },
    getTeachersConfig() {
      getTeacherConfig().then(res => {
        if (res.success) {
          if (res.result && res.result != null) {
            this.config = JSON.parse(res.result.data);
          }
        } else {
          this.$message.error(res.message);
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/views/teachers.scss';
.banner-block {
  height: 300px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
</style>
